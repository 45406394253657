export default function RegisterForm(attrs) {
    this.first_name = attrs.first_name ? attrs.first_name : null
    this.last_name = attrs.last_name ? attrs.last_name : null
    this.email = attrs.email ? attrs.email : null
    this.region_id = attrs.region_id ? attrs.region_id : null
    this.district_id = attrs.district_id ? attrs.district_id : null
    this.address = attrs.address ? attrs.address : null
    this.location_work = attrs.location_work ? attrs.location_work : null
    this.position = attrs.position ? attrs.position : null
    this.education = attrs.education ? attrs.education : null
    this.education_name = attrs.education_name ? attrs.education_name : null
    this.specialization = attrs.specialization ? attrs.specialization : null
    this.about = attrs.about ? attrs.about : null
}
