<template>
  <div class="course_reviews_block" v-if="reviews && reviews.length">
    <div class="course_reviews_container">
      <div class="course_reviews_label">
        Отзывы о курсе
      </div>
      <div class="course_reviews_slider">
        <div class="courses_slider_arrow_left" @click="prevPage"/>
        <div class="courses_slider_arrow_right" @click="nextPage"/>
        <Carousel
          style="width: 100% !important;"
          :paginationEnabled="false"
          v-if="reviews && reviews.length"
          :per-page="1"
          navigationNextLabel="next"
          :navigateTo="manualNavigation">
          <Slide v-for="(review) in reviews">
            <div class="course_reviews_info">
              <div class="course_reviews_info_label">
                {{ review.course_title }}
              </div>

              <div class="course_reviews_info_rating">
                <svg width="173" height="35" viewBox="0 0 173 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.8933 25.7411C17.3234 25.4815 17.8619 25.4815 18.292 25.7411L23.9073 29.1303C24.9322 29.7488 26.1965 28.83 25.9245 27.6642L24.4343 21.2764C24.3202 20.7871 24.4865 20.275 24.8662 19.946L29.8293 15.6464C30.7342 14.8626 30.2505 13.3763 29.0576 13.2751L22.5256 12.7209C22.0254 12.6785 21.5898 12.3627 21.3939 11.9005L18.8387 5.87156C18.3724 4.77127 16.813 4.77127 16.3466 5.87156L13.7914 11.9005C13.5955 12.3627 13.1599 12.6784 12.6597 12.7209L6.1277 13.2751C4.93484 13.3763 4.45115 14.8626 5.35598 15.6464L10.3192 19.946C10.6989 20.275 10.8651 20.7871 10.751 21.2764L9.2608 27.6642C8.98883 28.83 10.2531 29.7488 11.278 29.1303L16.8933 25.7411Z"
                    stroke="#FFD101" stroke-width="1.80443"  :class="{ 'rating-star': review.rating>=1 }"
                  />
                  <path
                    d="M52.0789 25.7411C52.509 25.4815 53.0474 25.4815 53.4775 25.7411L59.0928 29.1303C60.1177 29.7488 61.382 28.83 61.1101 27.6642L59.6199 21.2764C59.5058 20.7871 59.672 20.275 60.0517 19.946L65.0149 15.6464C65.9197 14.8626 65.436 13.3763 64.2432 13.2751L57.7111 12.7209C57.211 12.6785 56.7754 12.3627 56.5795 11.9005L54.0242 5.87156C53.5579 4.77127 51.9985 4.77127 51.5322 5.87156L48.9769 11.9005C48.781 12.3627 48.3455 12.6784 47.8453 12.7209L41.3132 13.2751C40.1204 13.3763 39.6367 14.8626 40.5415 15.6464L45.5047 19.946C45.8844 20.275 46.0507 20.7871 45.9365 21.2764L44.4463 27.6642C44.1744 28.83 45.4387 29.7488 46.4636 29.1303L52.0789 25.7411Z"
                    stroke="#FFD101" stroke-width="1.80443"   :class="{ 'rating-star': review.rating>=2 }"/>
                  <path
                    d="M87.2664 25.7411C87.6965 25.4815 88.2349 25.4815 88.665 25.7411L94.2803 29.1303C95.3052 29.7488 96.5695 28.83 96.2976 27.6642L94.8074 21.2764C94.6933 20.7871 94.8595 20.275 95.2392 19.946L100.202 15.6464C101.107 14.8626 100.624 13.3763 99.4307 13.2751L92.8986 12.7209C92.3985 12.6785 91.9629 12.3627 91.767 11.9005L89.2117 5.87156C88.7454 4.77127 87.186 4.77127 86.7197 5.87156L84.1644 11.9005C83.9685 12.3627 83.533 12.6784 83.0328 12.7209L76.5007 13.2751C75.3079 13.3763 74.8242 14.8626 75.729 15.6464L80.6922 19.946C81.0719 20.275 81.2382 20.7871 81.124 21.2764L79.6338 27.6642C79.3619 28.83 80.6262 29.7488 81.6511 29.1303L87.2664 25.7411Z"
                    stroke="#FFD101" stroke-width="1.80443" :class="{ 'rating-star': review.rating>=3 }"/>
                  <path
                    d="M121.385 24.9687C120.668 24.536 119.771 24.536 119.054 24.9687L113.439 28.3578C113.097 28.564 112.676 28.2577 112.766 27.8691L114.257 21.4813C114.447 20.666 114.17 19.8123 113.537 19.2641L108.574 14.9645C108.272 14.7032 108.433 14.2078 108.831 14.174L115.363 13.6199C116.197 13.5491 116.923 13.0228 117.249 12.2526L119.804 6.22363C119.96 5.85687 120.48 5.85687 120.635 6.22363L123.19 12.2526C123.517 13.0228 124.243 13.5491 125.076 13.6199L131.608 14.1741C132.006 14.2078 132.167 14.7032 131.866 14.9645L126.902 19.2641C126.27 19.8123 125.992 20.666 126.183 21.4813L127.673 27.8691C127.764 28.2577 127.342 28.564 127 28.3578L121.385 24.9687Z"
                    :class="{ 'rating-star': review.rating>=4 }" stroke="#FFD101" stroke-width="1.80443"/>

                  <path
                    stroke="#FFD101"
                    d="M156.573 24.9687C155.856 24.536 154.958 24.536 154.242 24.9687L148.626 28.3578C148.285 28.564 147.863 28.2577 147.954 27.8691L149.444 21.4813C149.634 20.666 149.357 19.8123 148.724 19.2641L143.761 14.9645C143.46 14.7032 143.621 14.2078 144.018 14.174L150.55 13.6199C151.384 13.5491 152.11 13.0228 152.437 12.2526L154.992 6.22363C155.147 5.85687 155.667 5.85687 155.822 6.22363L158.378 12.2526C158.704 13.0228 159.43 13.5491 160.264 13.6199L166.796 14.1741C167.193 14.2078 167.355 14.7032 167.053 14.9645L162.09 19.2641C161.457 19.8123 161.18 20.666 161.37 21.4813L162.86 27.8691C162.951 28.2577 162.53 28.564 162.188 28.3578L156.573 24.9687Z"
                    :class="{ 'rating-star': review.rating>=5 }" stroke-width="1.80443"/>
                </svg>

                <span style="margin-left: 1rem">{{ review.rating }}</span>
              </div>
              <div class="course_reviews_info_text">
                {{ review.review }}
              </div>
              <div class="course_reviews_info_job">
                {{ review.course_title }}
              </div>
              <div class="course_reviews_info_name">
                {{ review.user.name }}
              </div>
            </div>
            <div class="course_reviews_photo_block"
                 :class="{'base64': review.user.avatar.substring(0, 5) === 'data:'}"
                 :style="'background-image: url(\'' + review.user.avatar + '\')'">
            </div>
          </Slide>
        </Carousel>
      </div>
      <div class="course_reviews_names" style="overflow-x: auto">
        <div
          :class="{'active': manualNavigation === id}"
          class="course_reviews_names_item cursor-pointer"
          @click="setActive(id)"
          v-for="(review, id) in reviews">
          <div class="course_reviews_names_photo"
               :class="{'base64': review.user.avatar.substring(0, 5) === 'data:'}"
               :style="'background-image: url(\'' + review.user.avatar + '\')'"></div>
          <div class="course_reviews_names_desc">
            <div class="course_reviews_names_name">
              {{ review.user.name }}
            </div>
            <div class="course_reviews_names_course">
              {{ review.course_title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  props: ['reviews'],
  name: 'CourseReviews',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      manualNavigation: 0,
    }
  },
  methods: {
    nextPage() {
      if (this.reviews) {
        if (this.manualNavigation >= this.reviews.length) {
          this.manualNavigation = 0
        } else this.manualNavigation = this.manualNavigation + 1
      }
    },
    prevPage() {
      if (this.reviews) {
        if (this.manualNavigation <= 0) {
          this.manualNavigation = this.reviews.length
        } else this.manualNavigation = this.manualNavigation - 1
      }
    },
    setActive(id) {
      this.manualNavigation = id
    }
  },
  mounted() {
    setTimeout(() => {
      console.log('reviews', this.reviews)
    }, 2000)

  }
}
</script>

<style scoped>
.rating-star {
  fill: #FFD101;
}

.cursor-pointer {
  cursor: pointer;
}

.course_block .course_reviews_block .course_reviews_container .course_reviews_names .course_reviews_names_item .course_reviews_names_photo.base64 {
  background-size: 105%;
}

.course_block .course_reviews_block .course_reviews_container .course_reviews_slider .course_reviews_photo_block.base64 {
  background-size: 130%;
}

.VueCarousel-slide {
  display: flex;
}
@media (max-width: 800px) {
  .VueCarousel-slide {
    display: block;
  }
}
</style>
