<template>
  <div class="course_block" v-loading.fullscreen.lock="loading">
    <div class="course_header_block">
      <div class="course_header_container">
        <div class="course_header_label">
          <div class="course_header_text">
            {{ course.title }}
          </div>
          <div class="course_header_info">
            <div class="course_header_lang">{{ course.language === 'Казахский' ? $t("kz") : $t("ru") }}</div>
            <!--<div class="course_header_difficulty">{{ course.difficulty }}</div>-->
            <!-- <div class="course_header_duration">40 часов</div>
            <a class="course_header_link">Поделиться</a> -->
            <!-- <div class="course_header_rating">{{ course['rating'] }}</div> -->
          </div>
          <div class="course_desc_label">

            <div class="course_header_img course_header_img_mobile">
              <img :src="course['preview_image']" alt="">
              <!-- <div class="course_header_img_back"
                  :style="'background-image: url(\'' + course['preview_image'] + '\');'"></div> -->
              <span v-if="logged" class="w-100">
              <router-link
                v-if="dataIsCompleted && course.registered"
                class="float-right course_header_img_mobile"
                :class="{'mt-3': dataIsCompleted}"
                :to="{name: 'about-course', params: {slug: course.slug}}"
              >
                {{ $t('Continue_course') }}
              </router-link>
              <button v-else @click="enrollment"
                      class="float-right course_header_img_mobile">{{ $t('Sign_up_for_a_course') }}</button>
                <!--            <button v-else @click="openRegisterForm" class="float-right">Записаться на курс</button>-->
            </span>
              <router-link v-if="!logged" :to="'/signin?redirect=/course/' + course.slug">{{
                  $t('Sign_up_for_a_course')
                }}
              </router-link>
  <!--            <button v-if="course.registered" @click.prevent="unsubscribe" class="unsubscribe mt-3">{{ $t('Unsubscribe') }}</button>-->
            </div>

            <div class="course_desc_text" v-html="course['short_detail']"></div>
            <div class="course_desc_enroll">
              {{ $t('Registered_for_the_course') }}: {{ course['enrollments'] }}
            </div>
            <div class="course_desc_date">
              <!--            Опубликовано: {{ getDate(course.date) }}-->
            </div>
          </div>
        </div>
        <div class="course_header_img course_header_img_desk">
              <img :src="course['preview_image']" alt="">
              <!-- <div class="course_header_img_back"
                  :style="'background-image: url(\'' + course['preview_image'] + '\');'"></div> -->
          <span v-if="logged" class="w-100">
            <router-link
              v-if="dataIsCompleted && course.registered"
              class="float-right course_link"
              :class="{'mt-3': dataIsCompleted}"
              :to="{name: 'about-course', params: {slug: course.slug}}"
            >
              {{ $t('Continue_course') }}
            </router-link>
            <button v-else @click="enrollment" class="float-right">{{ $t('Sign_up_for_a_course') }}</button>
            <!--            <button v-else @click="openRegisterForm" class="float-right">Записаться на курс</button>-->
          </span>
          <router-link v-if="!logged" :to="'/signin?redirect=/course/' + course.slug">{{
              $t('Sign_up_for_a_course')
            }}
          </router-link>
<!--          <button v-if="course.registered" @click.prevent="unsubscribe" class="unsubscribe mt-3">{{ $t('Unsubscribe') }}</button>-->
        </div>
      </div>
    </div>
    <div class="course_learning_block">
      <div class="course_learning_container">
        <div class="course_learning_label">
          {{ $t('You_will_learn') }}
        </div>
        <div class="course_learning_list">
          <div class="course_learning_list">
            <div class="course_learning_list_item">
<!--              <div v-for="learn in course.what_learns">-->
<!--                {{ learn.detail }}-->
<!--              </div>-->
              <div class="what_learn_course">
                <span v-for="learn in courseLearn1" class="what_learn_course_item" :style="whatLearnCourseLength">{{ learn.detail }}</span>
              </div>
              <div class="what_learn_course">
                <span v-for="learn in courseLearn2" class="what_learn_course_item" :style="whatLearnCourseLength">{{ learn.detail }}</span>
              </div>
              <div class="what_learn_course">
                <span v-for="learn in courseLearn3" class="what_learn_course_item" :style="whatLearnCourseLength">{{ learn.detail }}</span>
              </div>
              <div class="what_learn_course">
                <span v-for="learn in courseLearn4" class="what_learn_course_item" :style="whatLearnCourseLength">{{ learn.detail }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_program_block">
      <div class="course_program_container">
        <div class="course_program_structure">
          <div class="course_program_structure_label">
            {{ $t('Course_program') }}
          </div>
          <div v-for="(module, moduleKey) in course.modules">
            <div
              @click="toggleModule(moduleKey)"
              class="course_program_structure_module cursor-pointer"
              :class="{'active': activeModule === moduleKey}">
              {{ module.title }}
            </div>

            <transition name="slide" v-for="(theme, themeKey) in module['themes']" :key="themeKey">
              <div v-if="activeModule === moduleKey">
                <div
                  @click="toggleTheme(themeKey)"
                  class="course_program_structure_theme cursor-pointer"
                  :class="{'active': activeTheme === themeKey}">
                  {{ theme.name }}
                </div>

                <transition name="slide">
                  <div class="course_program_structure_text" v-if="activeTheme === themeKey"
                       v-html="theme.description"></div>
                </transition>
                <div class="course_program_structure_material container">
                  <transition name="slide">
                    <div v-if="activeTheme === themeKey">
                      <span v-if="theme['materials'] && theme['materials'].length">{{ $t('Teaching_material') }}</span>
                      <div class="row my-4">
                        <div class="col-4" v-for="material in theme['materials']">
                          <div class="card material_block p-3 text-center mb-4">
                            <img v-if="material.type === 'image'" width="65" src="../../../assets/images/image.svg" alt=""
                                 class="mx-auto">
                            <img v-if="material.type === 'video'" width="65" src="../../../assets/images/video.svg" alt=""
                                 class="mx-auto">
                            <img v-if="material.type === 'presentation'" width="65"
                                 src="../../../assets/images/theme_one_slide.svg" alt=""
                                 class="mx-auto">
                            <img v-if="material.type === 'audio'" width="65"
                                 src="../../../assets/images/theme_one_audio.svg" alt=""
                                 class="mx-auto">
                            <img v-if="material.type === 'text'" width="65"
                                 src="../../../assets/images/theme_one_pencil.svg" alt=""
                                 class="mx-auto">
                                                        <div>{{ materialTypeName(material.type) }}</div>
<!--                            <div>{{ materialType(material.type) }}</div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="course_program_desc">
          <div class="course_program_label">
            {{ $t('Course_description') }}
          </div>
          <div class="course_program_text">
            <div class="course_program_text_inner" v-html="course.detail"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Записаться на курс"
      :visible.sync="modalVisible"
      width="50%"
      :before-close="handleClose">
      <div class="w-100">
        <el-form ref="form" :model="registerForm">
          <div class="row">
            <div class="col-md-6">
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Surname')"
                  v-model="registerForm.last_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Name')"
                  v-model="registerForm.first_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Email')"
                  data-required="email"
                  data-invalid-message="Пожалуйста введите корректный email"
                  v-model="registerForm.email"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.region_id"
                  :placeholder="$t('Choose_region')"
                  @change="getCities"
                >
                  <el-option
                    v-for="state in states"
                    :label="state.name"
                    :value="state.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.district_id"
                  :placeholder="$t('Choose_district')"
                  @change="getDistricts"
                >
                  <el-option
                    v-for="city in cities"
                    :label="city.name"
                    :value="city.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.address"
                  :placeholder="$t('Choose_locality')"
                >
                  <el-option
                    v-for="district in districts"
                    :label="district.name"
                    :value="district.name"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Place_of_work')"
                  v-model="registerForm.location_work"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Job_title')"
                  v-model="registerForm.position"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.education"
                  :placeholder="$t('Choose_your_level_of_education')"
                >
                  <el-option label="Высшее" value="high"></el-option>
                  <el-option label="Среднее" value="medium"></el-option>
                  <el-option label="Среднееспециальное" value="specialized"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Name_of_educational_institution')"
                  v-model="registerForm.education_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('Specialization')"
                  v-model="registerForm.specialization"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  :placeholder="$t('About_me')"
                  v-model="registerForm.about"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer float-left">
        <el-button type="primary" @click="updateProfile">{{ $t('Sign_up') }}</el-button>
        <el-button @click="modalVisible = false">{{ $t('Cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="successModalVisible"
      width="50%"
      :before-close="successHandleClose"
    >
      <div class="text-center">
        <svg class="mb-4" width="172" height="171" viewBox="0 0 172 171" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M115.391 69.4688L76.2029 106.875L56.6094 88.1719" stroke="white"
                stroke-width="7.22" stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M86 149.625C121.415 149.625 150.125 120.915 150.125 85.5C150.125 50.0847 121.415 21.375 86 21.375C50.5847 21.375 21.875 50.0847 21.875 85.5C21.875 120.915 50.5847 149.625 86 149.625Z"
            stroke="white" stroke-width="7.22" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <p class="my-40 bold fs30 c_white text-center modal_window_text">{{ $t('Your_entry_has_been_successfully_completed') }}</p>
        <p class="br-10 bold fs20 c_white text-center py-28 px-24 bg_primary_dark modal_window_text">
          {{ $t('You_will_receive_an_additional_notification_by_email') }} </p>

        <el-button
          class="my-4"
          type="text"
          @click="$router.push({ name: 'about-course', params: { slug: $route.params.slug } })"
        >
          {{ $t('Go_to_course') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CourseReviews from "@/views/old/Courses/CourseReviews.vue";
import CourseQuestions from "@/views/old/Courses/CourseQuestions.vue";
import Vue from "vue";
import RegisterForm from "@/models/register";

export default {
  name: 'CourseDetails',
  components: {
    CourseReviews,
    CourseQuestions
  },
  data() {
    return {
      loading: false,
      course: {},
      states: [],
      cities: [],
      districts: [],
      activeModule: false,
      activeTheme: false,
      modalVisible: false,
      successModalVisible: false,
      registerForm: new RegisterForm({}),
      courseLearn1: [],
      courseLearn2: [],
      courseLearn3: [],
      courseLearn4: [],
    }
  },
  methods: {
    materialTypeName(type) {
      if (type === 'video') {
        return 'Видеолекция'
      } else if (type === 'image') {
        return 'Рисунок'
      } else if (type === 'presentation') {
        return 'Презентация'
      } else if (type === 'audio') {
        return 'Аудиолекция'
      } else {
        return 'Текст'
      }
    },
    enrollment() {
      let apiRoot = API_ROOT
      let slug = this.$route.params.slug
      this.$http.post(`${apiRoot}/api/course/enroll/${slug}`)
        .then(() => {
          this.successModalVisible = true
        })
    },
    getCourseDetails() {
      this.loading = true;
      let apiRoot = API_ROOT
      let slug = this.$route.params.slug
      this.$http.get(`${apiRoot}/api/course/${slug}`)
        .then(({ body }) => {
          this.loading = false
          this.course = body
          this.whatLearn()
        })
        .catch(err => {
          this.loading = false
          if (err.status === 404) {
            this.$router.push({name: 'not-found'})
          }
          console.log(err)
        })
        .finally(() => {
          this.loading = false
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    },
    openRegisterForm() {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/user`)
        .then(res => {
          this.registerForm = new RegisterForm(res['body'] ? res['body'] : {})
        })
      this.getStates()
      this.modalVisible = true
    },
    getStates() {
      this.registerForm.region_id = null;
      this.registerForm.district_id = null;
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/states`)
        .then(res => {
          if (res.data) {
            this.states = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    getCities(stateId) {
      this.registerForm.district_id = null;
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/cities/${stateId}`)
        .then(res => {
          if (res.data) {
            this.cities = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    getDistricts(cityId) {
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/districts/${cityId}`)
        .then(res => {
          if (res.data) {
            this.districts = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    unsubscribe() {
      let apiRoot = API_ROOT
      let slug = this.$route.params.slug
      this.$http.post(`${apiRoot}/api/course/unsubscribe/${slug}`)
        .then(res => {
          if (res.data) {
            Vue.toastr({
              message: 'Success',
              description: 'Вы успешно отписались от курса',
              type: 'success'
            })
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.course.registered = false
        })
    },
    getDate(date) {
      if (!date)
        return
      const formatter = new Intl.DateTimeFormat("ru-RU", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      let d = new Date(date);
      return formatter.format(d)
    },
    toggleModule(id) {
      if (this.activeModule !== id) {
        this.activeModule = id
        this.activeTheme = false
      } else {
        this.activeModule = false
        this.activeTheme = false
      }
    },
    toggleTheme(id) {
      if (this.activeTheme !== id) {
        this.activeTheme = id
      } else {
        this.activeTheme = false
      }
    },
    handleClose() {
      this.modalVisible = false;
    },
    successHandleClose() {
      this.successModalVisible = false;
    },
    updateProfile() {
      let apiRoot = window.API_ROOT;
      this.$http.post(`${apiRoot}/api/course/update-profile`, {
        ...this.registerForm,
        course_id: this.course.id,
      })
        .then(res => {
          if (res.data) {
            this.modalVisible = false;
            this.successModalVisible = true;
          }
        })
        .catch(err => {
          Vue.toastr({
            message: 'Ошибка',
            description: 'Не правильно введены данные',
            type: 'error'
          })
        })
        .finally(() => {
        })
    },
    whatLearn() {
      for (let i = 0; i < this.course['what_learns'].length; i++) {
        if (i === 0 || i === 4 || i === 8 || i === 12) {
          this.courseLearn1.push((this.course['what_learns'][i]))
        }

        if (i === 1 || i === 5 || i === 9 || i === 13) {
          this.courseLearn2.push((this.course['what_learns'][i]))
        }

        if (i === 2 || i === 6 || i === 10 || i === 14) {
          this.courseLearn3.push((this.course['what_learns'][i]))
        }

        if (i === 3 || i === 7 || i === 11 || i === 15) {
          this.courseLearn4.push((this.course['what_learns'][i]))
        }
      }
    }
  },
  computed: {
    dataIsCompleted: function () {
      /**
       * временно отключаем заполнение профиля когда она данные не заполнены
       */
      return true;

      if (!this.course) return false;
      let user = this.course.user
      let completed = false
      if (
        user.first_name
        && user.last_name
        && user.email
        && user.address
        && user.location_work
        && user.position
        && user.region_id
        && user.district_id
        && user.about
        && user.specialization
        && user.education
        && user.education_name
      ) completed = true
      return completed
    },
    logged: function () {
      let token = localStorage.getItem('token')
      return token !== null && token !== ''
    },
    whatLearnCourseLength() {
      if (this.courseLearn1.length === 1) {
        return 'width: 100%'
      } else if (this.courseLearn1.length === 2) {
        return 'width: 50%'
      } else if (this.courseLearn1.length === 3) {
        return 'width: 33%'
      } else {
        return 'width: 25%'
      }
    }
  },
  mounted() {
    this.getCourseDetails()
  }
}
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.slide-enter-active {
  transition: all .3s ease;
}

.slide-leave-active {
  transition: all .3s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.el-button {
  background: #16C95D;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 8px;
  color: #fff;
}

.course_header_block {
  height: auto !important;
  padding-top: 70px;
  padding-bottom: 80px;
}

.course_header_label {
  height: auto !important;
}

.course_header_container {
  align-items: flex-start;
}

.course_header_text {
  margin-bottom: 40px;
}

.course_header_img {
  top: 0 !important;
  right: 0 !important;
  position: relative;
  justify-content: initial;
  gap: 20px;
  height: auto !important;

  img {
    width: 90%;
  }
}

.course_desc_label {
  width: 100%;
}

.course_program_structure_module {
  height: auto;
}

.course_program_structure_material .material_block {
  min-width: 200px;
}

@media screen and (max-width: 900px) {
  .course_header_block {
    padding-top: 20px;
    padding-bottom: 16px;
  }
  .course_header_text {
    margin-bottom: 10px;
  }
  .course_header_img {
    img {
      width: 100%;
    }
  }

  .course_header_img a, .course_header_img button {
    background: #007BFC;
    font-size: 14px;
    height: 40px;
    border-radius: 10px;
    width: 100%;
  }

  .course_learning_container {
    padding: 32px !important;
    margin: 0 20px 60px;
  }
  .course_main_container, .course_learning_container, .course_program_container {
    flex-direction: column;
    height: auto;
  }
}

</style>
